//Scroll Smooth
function scrollToIdOnClick(event) {
    event.preventDefault();
    console.log(event.target);

      //Mobile AND DESK
    if ($(window).width() < 991)  {
      const to = getScrollTopByHref(event.target) + 69;
      scrollToPosition(to);
    }else{
      const to = getScrollTopByHref(event.target) - 114;
      scrollToPosition(to);
    }
}
  
function scrollToPosition(to) {
  smoothScrollTo(0, to, 100);
}

function getScrollTopByHref(element) {
  const id = element.getAttribute("href");
  return document.querySelector(id).offsetTop;
}

function smoothScrollTo(endX, endY, duration) {
  const startX = window.scrollX || window.pageXOffset;
  const startY = window.scrollY || window.pageYOffset;
  const distanceX = endX - startX;
  const distanceY = endY - startY;
  const startTime = new Date().getTime();

  duration = typeof duration !== "undefined" ? duration : 400;

  // Easing function
  const easeInOutQuart = (time, from, distance, duration) => {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  const timer = setInterval(() => {
    const time = new Date().getTime() - startTime;
    const newX = easeInOutQuart(time, startX, distanceX, duration);
    const newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      clearInterval(timer);
    }
    window.scroll(newX, newY);
  }, 1000 / 60); // 60 fps
}


//MODAL
function getCategoryContent(category){
  switch(category){
      case "Quarto":
          return [
              "Utilizar luz noturna para iluminar o caminho da cama até o banheiro",
              "Posicionar luminárias próximo à cama",
              "Ter um telefone em caso de emergência",
              "Optar por cama com altura adequada para subir e descer com facilidade",
              "Não deixar objetos espalhados pelo chão",
              "Guardar roupas e objetos no alcance do idoso"
          ];
      case "Cozinha":
          return [
              "Deixar os itens mais utilizados no alcance do idoso",
              "Nunca usar cadeira para alcançar armários e prateleiras mais altos",
              "Prender armários para que possam ser usados de apoio em caso de emergência",
              "Limpar qualquer vestígio de líquido e alimento do chão imediatamente",
              "Evitar o uso de tapetes"
          ];
      case "Banheiro":
          return [
              "Usar tapete antiderrapante",
              "Instalar barras de apoio no box e próximo ao vaso",
              "Manter no box uma cadeira de plástico firme para o idoso tomar banho sentado, se necessário"
          ];
      case "Chao":
          return [
              "Remover móveis que dificultem a mobilidade",
              "Remover tapetes escorregadios",
              "Remover objetos espalhados pelo chão",
              "Remover fios das áreas de passagem"
          ];
      case "Escada":
          return [
              "Nunca deixar objetos na escada",
              "Realizar manutenção dos degraus",
              "Realizar manutenção do corrimão",
              "Instalar iluminação na região da escada",
              "Remover tapetes próximos à escada"
          ];
      default:
          return [];
  }
}

function showModal(category, content){
  $("#modal-title").text(category);
  $(".modal-list").empty(); // Limpa o conteúdo anterior da lista

  var list = $(".modal-list");
  content.forEach(function(item){
      list.append("<li>" + item + "</li>");
  });

  $(".house-modal").addClass('opened');
}

function hideModal() {
  $(".house-modal").removeClass('opened');
}
