function init() {
    //Smooth Scroll
    const scrollSmooth = document.querySelectorAll('#main-nav li button, #main-banner .btn-our-case-banner');
    
    scrollSmooth.forEach(item => {
        item.addEventListener('click', scrollToIdOnClick);
    });

    //SLICK EVENT
    $('#secret-sauce .cards').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        // centerMode: true,
        variableWidth: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('#news .cards').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        variableWidth: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    //Fade In Fade Out -- Header --
    $(window).scroll(function(){
        var businessSectionPosition = $('#business').offset().top;
        var currentPosition = $(window).scrollTop();
        if (currentPosition >= businessSectionPosition) {
            $('#main-nav').fadeIn();
        } else {
            $('#main-nav').fadeOut();
        }
    });


    $("#phone").mask('(00)00000-0000');

    $( "#formDownload" ).on( "submit", function( event ) {
        event.preventDefault();

        const dadosForm = $('#formDownload').serialize();

        const name = document.getElementById("name");
        const phone = document.getElementById("phone");
        const email = document.getElementById("email");
        const hospital_name = document.getElementById("hospital_name");
        const job_title = document.getElementById("job_title");
        
        /*
        if(hcaptcha.getResponse() == ""){
            alert("Preencha o Captcha");
            return false;
        }
        */

        var request = $.ajax({
            url: "api.php",
            method: "POST",
            data: dadosForm,
            dataType: "json"
        });
           
        request.done(function( msg ) {
            if(msg.response){
                $('.submit-modal').fadeIn();
                $('.submit-modal').css('display', 'flex');

                name.value = "";
                phone.value = "";
                email.value = "";
                hospital_name.value = "";
                job_title.value = "";
            }
            else {
                alert(msg.msg);
            }
        });
           
        request.fail(function( jqXHR, textStatus ) {
            alert( "Request failed: " + textStatus );
        });
        
    });

    $('.submit-modal .close-modal').click(function(){
        $('.submit-modal').fadeOut();
    });
}

// window load binds
window.onload = init;

function DOMLoaded() {
    if (helpers.iOS) {
        document.querySelector("html").classList.add("ios");
    } else if (helpers.IE()) {
        document.querySelector("html").classList.add("ie");
    }
}

// domcontent binds
document.addEventListener("DOMContentLoaded", DOMLoaded);